const prod = {
  MOLAR_URL: '/api/v1',
  MADNESS_URL: '/madness/v1',
};

const dev = {
  MOLAR_URL: 'http://localhost:8000/api/v1',
  MADNESS_URL: 'http://localhost:8080/madness/v1',
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;
