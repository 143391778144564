import { useState, useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import React from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

function LabSelector(props) {
  const display = props.display ? 'flex' : 'none';
  return (
    <FormControl sx={{ display: { display }, flexGrow: 1 }}>
      <Select
        variant="standard"
        onChange={props.handleChange}
        value={props.lab}
      >
        {props.labs.map((elem) => (
          <MenuItem value={elem.lab_id}>{elem.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function MadnessPage(props) {
  const [labs, setLabs] = useState([]);
  let [searchParams, setSearchParams] = useSearchParams();
  let navigate = useNavigate();

  useEffect(() => {
    if (props.withLabSelector) {
      props.molar.queryDatabase({ types: 'lab' }, 20, 0).then((data) => {
        setLabs(data);
        setSearchParams({
          ...Object.fromEntries([...searchParams.entries()]),
          lab: searchParams.get('lab') || data[0].lab_id,
        });
      });
    }
  }, []);
  const changeLab = (event) => {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      lab: event.target.value,
    });
  };
  return (
    <Paper elevation={4} sx={{ px: 4, py: 2, my: 1 }}>
      <Stack
        m="auto"
        sx={{ flexGrow: 1, justifyContent: 'center', display: 'flex', pb: 2 }}
      >
        <Grid container spacing={2} pt={2}>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            <div hidden={props.showBackButton === false}>
              <Button onClick={() => navigate(-1)}>Back</Button>
            </div>
            <Typography variant="h5">{props.title}</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <LabSelector
              display={props.withLabSelector}
              labs={labs}
              lab={searchParams.get('lab')}
              handleChange={changeLab}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {props.children}
          </Grid>
        </Grid>
      </Stack>
    </Paper>
  );
}

MadnessPage.defaultProps = {
  showBackButton: false,
};
export default MadnessPage;
