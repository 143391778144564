import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

function MadnessHeader(props) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Madness Platform
          </Typography>
          <Button
            href={process.env.PUBLIC_URL + '/'}
            color="inherit"
            disabled={!props.isLoggedIn}
          >
            Overview
          </Button>
          <Button
            href={process.env.PUBLIC_URL + '/synthesis'}
            color="inherit"
            disabled={!props.isLoggedIn}
          >
            Synthesis
          </Button>
          <Button
            href={process.env.PUBLIC_URL + '/machines'}
            color="inherit"
            disabled={!props.isLoggedIn}
          >
            Machines
          </Button>
          <Button
            href={process.env.PUBLIC_URL + '/stock'}
            color="inherit"
            disabled={!props.isLoggedIn}
          >
            Stock
          </Button>
          <Button
            href={process.env.PUBLIC_URL + '/events'}
            color="inherit"
            disabled={!props.isLoggedIn}
          >
            Events
          </Button>
          <Button
            href={process.env.PUBLIC_URL + '/admin'}
            color="inherit"
            disabled={!props.isAdmin}
          >
            Admin
          </Button>
          <Button
            color="inherit"
            disabled={!props.isLoggedIn}
            onClick={props.handleLogout}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default MadnessHeader;
