import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import MadnessPage from '../../components/MadnessPage';

function MadnessEventPage(props) {
  return (
    <MadnessPage withLabSelector={false} title="Events log">
      <Typography>Nothing there yet</Typography>
    </MadnessPage>
  );
}

export default MadnessEventPage;
