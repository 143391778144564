import './app.scss';
import { useState, useEffect, lazy, Suspense } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import { Routes, Route } from 'react-router-dom';
import MadnessHeader from './components/MadnessHeader';
import MolarUserInfo from './components/MolarUserInfo';
import MolarService from './services/molar';
import MadnessService from './services/madness';
import MadnessEventPage from './content/MadnessEventPage';
// import StockMgmtPage from './content/StockMgmtPage';

import { config } from './settings';

const MadnessLoginPage = lazy(() => import('./content/MadnessLoginPage'));
const MadnessSynthesisPage = lazy(() =>
  import('./content/MadnessSynthesisPage')
);
const MadnessSynthesisEdit = lazy(() =>
  import('./content/MadnessSynthesisEdit')
);
const MadnessAdminPage = lazy(() => import('./content/MadnessAdminPage'));
const MadnessOverviewPage = lazy(() => import('./content/MadnessOverviewPage'));
const MadnessStockPage = lazy(() => import('./content/MadnessStockPage'));
const MadnessMachinePage = lazy(() => import('./content/MadnessMachinePage'));
const MadnessXDLEditor = lazy(() => import('./content/MadnessXDLEditor'));

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState('');
  const [userInfo, setUserInfo] = useState({});
  const molar = new MolarService(config.MOLAR_URL, 'madness_laser');
  const madness = new MadnessService(config.MADNESS_URL);

  useEffect(() => {
    molar.testToken().then((success) => {
      if (success) {
        molar.setToken();
        setLoggedIn(success);
        molar.getUserInfo().then((info) => {
          setUserInfo(info);
        });
      }
    });
  }, []);

  const handleLogin = (username, password, rememberMe) => {
    molar.login(username, password, rememberMe).then((success) => {
      if (success) {
        setLoggedIn(true);
        molar.getUserInfo().then((info) => {
          setUserInfo(info);
          console.log(info);
        });
      } else {
        setLoginErrorMsg(
          'Oops! Something wrong happened! Please check your username and password'
        );
        return;
      }
    });
  };

  const handleRegister = (username, full_name, password) => {
    molar.register(full_name, username, password);
    setRegistered(true);
  };

  const handleLogout = () => {
    setLoggedIn(false);
    molar.logout();
  };

  if (registered) {
    return (
      <>
        <MadnessHeader isLoggedIn={false} />
        <p>
          Thank you for registering! Your account will be activated shortly.
        </p>
      </>
    );
  }

  if (!loggedIn) {
    return (
      <>
        <MadnessHeader isLoggedIn={false} />
        <Container maxWidth="sm">
          <Suspense fallback={<LinearProgress />}>
            <MadnessLoginPage
              handleLogin={handleLogin}
              handleRegister={handleRegister}
              loginErrorMsg={loginErrorMsg}
            />
          </Suspense>
        </Container>
      </>
    );
  }
  return (
    <>
      <MadnessHeader
        isLoggedIn={loggedIn}
        handleLogout={handleLogout}
        isAdmin={userInfo.is_superuser}
      />
      <MolarUserInfo userInfo={userInfo} />
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={8} lg={6} m="auto">
            <Suspense fallback={<LinearProgress />}>
              <Routes basename={process.env.PUBLIC_URL}>
                <Route
                  exact
                  path="/"
                  element={<MadnessOverviewPage molar={molar} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/synthesis'}
                  element={
                    <MadnessSynthesisPage molar={molar} madness={madness} />
                  }
                />
                <Route
                  path="/synthesis/:synthesisId"
                  element={
                    <MadnessSynthesisEdit molar={molar} madness={madness} />
                  }
                />
                <Route
                  path="/machines"
                  element={<MadnessMachinePage molar={molar} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/events'}
                  element={<MadnessEventPage molar={molar} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/stock'}
                  element={<MadnessStockPage molar={molar} />}
                />
                <Route
                  path={process.env.PUBLIC_URL + '/admin'}
                  element={
                    <MadnessAdminPage molar={molar} userInfo={userInfo} />
                  }
                />
                <Route
                  path={process.env.PUBLIC_URL + '/xdl/:hid'}
                  element={<MadnessXDLEditor molar={molar} />}
                />
              </Routes>
            </Suspense>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default App;
