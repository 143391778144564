import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useEffect } from 'react';

function MolarUserInfo(props) {
  useEffect(() => {}, [props.userInfo]);
  if (props.userInfo === null) {
    return <></>;
  }
  return (
    <Box sx={{ flexGrow: 1, justifyContent: 'center', display: 'flex' }}>
      <Typography variant="overline">
        Logged in as: {props.userInfo.full_name} ({props.userInfo.email})
      </Typography>
    </Box>
  );
}

export default MolarUserInfo;
