import axios from 'axios';

class MadnessService {
  constructor(serverURL) {
    this.client = axios.create({
      baseURL: serverURL,
      timeout: 10000,
    });
    this.token = localStorage.getItem('token');
    if (this.token !== null) {
      this.setMolarToken(this.token);
    }
  }

  setMolarToken(token) {
    this.client.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  }

  assemble_molecule(smilesA, smilesB, smilesC) {
    return this.client
      .post('assemble-fragments', null, {
        params: {
          smiles_a: smilesA,
          smiles_b: smilesB,
          smiles_c: smilesC,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.data.detail);
        }
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  acquire_synthesis(synthesis_id, machine_id, lab_id) {
    return this.client
      .post('acquire-synthesis', null, {
        params: {
          synthesis_id: synthesis_id,
          machine_id: machine_id,
          lab_id: lab_id,
        },
      })
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.data.detail);
        }
        return response.data;
      });
  }
}

export default MadnessService;
