import axios from 'axios';
import jwt_decode from 'jwt-decode';

class MolarService {
  constructor(serverURL, database_name) {
    this.database_name = encodeURIComponent(database_name);
    this.token = localStorage.getItem('token');
    this.client = axios.create({
      baseURL: serverURL,
      timeout: 30000,
    });
    if (this.token !== null) {
      this.setToken();
    }
  }

  setToken() {
    this.client.defaults.headers.common['Authorization'] =
      'Bearer ' + this.token;
  }

  login(username, password, rememberMe) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);
    return this.client
      .post('/login/access-token?database_name=' + this.database_name, params)
      .then((response) => {
        if (response.status !== 200) {
          throw Error(response.data.detail);
        }
        this.token = response.data.access_token;
        this.setToken();
        localStorage.setItem('token', this.token);
        return true;
      })
      .catch((error) => {
        return false;
      });
  }

  getTokenContent() {
    return jwt_decode(this.token);
  }

  getUserInfo() {
    return this.client
      .post('/login/test-token?database_name=' + this.database_name)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  }

  testToken() {
    return this.client
      .post('/login/test-token?database_name=' + this.database_name)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  logout() {
    this.token = null;
    localStorage.removeItem('token');
  }

  register(fullName, email, password) {
    return this.client
      .post('/user/register?database_name=' + this.database_name, {
        full_name: fullName,
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  getUsers() {
    return this.client
      .get('/user/?database_name=' + this.database_name)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  }

  updateUser(email, data) {
    return this.client
      .patch('/user/?database_name=' + this.database_name + '&email=' + email, {
        data: data,
      })
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  activateUser(email) {
    return this.client
      .patch(
        '/user/activate?database_name=' + this.database_name + '&email=' + email
      )
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  deactivateUser(email) {
    return this.client
      .patch(
        '/user/deactivate?database_name=' +
          this.database_name +
          '&email=' +
          email
      )
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  deleteUser(email) {
    return this.client
      .delete('/user/?database_name=' + this.database_name + '&email=' + email)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  recoverPassword(email) {}

  resetPassword(token, new_password) {}

  create(type, data) {
    return this.client
      .post('/eventstore/' + this.database_name, { type: type, data: data })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  viewEntries(limit, offset) {}

  queryDatabase(data, limit, offset) {
    return this.client
      .post('/query/' + this.database_name, data, {
        method: 'get',
        params: { offset: offset, limit: limit },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      });
  }

  update(data) {
    return this.client
      .post('/eventstore/update/' + this.database_name, data)
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  delete(type, uuid) {
    return this.client
      .delete('/eventstore/' + this.database_name, {
        data: { type: type, uuid: uuid },
      })
      .then((response) => {
        if (response.status === 200) {
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }
  acquireSynthesis(synthesis_id, lab_id, machine_id) {}
}

export default MolarService;
